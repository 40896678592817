@import "./Variables.scss";

.pagos-section {
  height: 100%;
  display: grid;
  grid-template-rows: 30% 70%;
  grid-template-columns: 1fr 1fr;
  background: rgb(249, 249, 249);
  background: linear-gradient(
    140deg,
    rgba(249, 249, 249, 1) 0%,
    rgba(232, 238, 249, 1) 40%,
    rgba(183, 232, 247, 1) 53%,
    rgba(134, 226, 245, 1) 73%,
    rgba(0, 209, 238, 1) 88%,
    rgba(0, 180, 242, 1) 94%
  );

  @media (max-width: 600px) {
    height: 100%;
    padding-top: 10%;
    grid-template-columns: 1fr;
    grid-template-rows: 10% 35% 35%;
  }
  h1 {
    margin-left: 16px;
    margin-top: 30px;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .ul-pagos {
    background-color: transparent;
    display: inline;
    grid-column-start: 1;
    grid-column-end: 2;
    justify-self: end;
    @media (max-width: 600px) {
      max-width: none;
    }
  }
  .paymentSection {
    
    display: flex;
    flex-flow: row wrap;
    grid-column-start: 2;
    grid-column-end: 3;
    justify-self: start;
    z-index: 1100;
    @media (max-width: 600px) {
      width: 70%;
      margin: 0 15%;
    }

    .paymentInfo {
      // margin-top: 40px;
      width: 100%;
      ul {
        display: inline-block;
        width: 30%;
      }
      .vinc-mp-txt {
        color: #5eb40d;
      }
      .desvinc-mp-txt {
        color: #b92e30;
      }
      .mpButton {
        display: inline-block;
        -webkit-text-size-adjust: 100%;
        border-collapse: collapse;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
        font-family: Proxima Nova, -apple-system, Helvetica Neue, Helvetica,
          Roboto, Arial, sans-serif;
        font-size: 18px;
        line-height: 1.25;
        color: inherit;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        // margin-left: 56px;
        padding-left: 16px;
        text-align: left;
        width: auto;
        background-color: transparent;
        z-index: 1100;
        &:hover {
          -webkit-text-size-adjust: 100%;
          border-collapse: collapse;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
          font-family: Proxima Nova, -apple-system, Helvetica Neue, Helvetica,
            Roboto, Arial, sans-serif;
          font-size: 18px;
          line-height: 1.25;
          color: inherit;
          text-align: left;
          box-sizing: border-box;
        }

        span {
          -webkit-text-size-adjust: 100%;
          border-collapse: collapse;
          -webkit-font-smoothing: antialiased;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
          font-family: Proxima Nova, -apple-system, Helvetica Neue, Helvetica,
            Roboto, Arial, sans-serif;
          box-sizing: border-box;
          text-decoration: none;
          border-radius: 6px;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.25;
          padding: 15px 42px;
          display: inline-block;
          min-width: 187px;
          text-align: center !important;
          width: fit-content !important;
          margin-right: 8px;
          font-weight: 100;
          &:hover {
            -webkit-text-size-adjust: 100%;
            border-collapse: collapse;
            -webkit-font-smoothing: antialiased;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
            font-family: Proxima Nova, -apple-system, Helvetica Neue, Helvetica,
              Roboto, Arial, sans-serif;
            box-sizing: border-box;
            text-decoration: none;
            border-radius: 6px;
            font-size: 16px;
            font-weight: 100;
            line-height: 1.25;
            display: inline-block;
            min-width: 187px;
            text-align: center !important;
            width: fit-content !important;
            margin-right: 8px;
            color: #fff;
            transition: background-color 0.2s ease-in;
            cursor: pointer;
          }
        }
        .vinc-mp {
          background: #009ee3;

          &:hover {
            background-color: #007eb5;
          }
        }
        .desvinc-mp {
          background: #bf4f51;
          &:hover {
            background-color: #b92e30;
          }
        }
      }
      input {
        margin-left: 56px+16px;
        // padding-left:16px;
        width: auto;
      }
    }
  }
  h1,
  .ul-pagos,
  .paymentSection {
    @media (max-width: 600px) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }

  .inputValor {
    @media (max-width: 844px) {
      width: 90%;
      margin: 10vh 5% 20px 5%;
    }
    @media (max-width: 600px) {
      margin: 5vh 5% 20px 5%;

    }
    // border: 2px solid $secondary-grey;

    height: 50px;
    // margin-left: 56px+16px;
    border-radius: 10px;
    padding: 0 3px;
    margin-top: 30px;
    width: auto;
    min-width: 260px;
    max-width: 260px;
    display: flex;

    // flex-flow:row wrap;
    // align-items:center;
    // justify-content:space-between
  }
  .buttonValor {
    // margin-left: 0!importa
    margin-top: 10px;
    // margin-top: 5px;
    button {
      text-transform: none;
    }
  }
}
