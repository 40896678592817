@import "./Variables.scss";

#explorer-comp {
  width: 100%;

  height: 100%;
  min-height: 100vh;
  background: url("../Assets/backgrounds/explorer.svg");
  background-image: url("../Assets/backgrounds/explorer.svg");
  background-repeat: no-repeat;
  font-family: $font-regular;
  background-size: cover;
  padding-bottom: 100px;
  overflow: visible !important;
  #categories-ctn {
    display: flex;
    flex-flow: column wrap;
  }

  .title {
    color: $darker-blue;
    margin: 20px 0 30px 0;
    text-align: center;
    font-family: $font-bold;
  }
  .noResult {
    // color:white;
    font-size: 20px;
    width: 28%;
    margin: 10px 36%;
    max-width: 320px;
    text-align: center;
  }
  .noResult-img {
    width: 16%;
    margin: 60px 42% 10px 42%;
  }
}
.css-6y3zoz {
  background-color: $tarjetas-background;
  border-radius: 25px;
  width: 80%;
  overflow: hidden !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: $text-grey !important;
  font-family: $font-regular;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: $darker-blue !important;
  font-family: $font-bold;
  // border-radius: 25px;
}
.users-explorer {
  position:relative; 
  z-index: 0;
  @media (min-width: 1400px) {
  
    width: 86%;
    margin: 0 7%;
    grid-template-columns: repeat(4, 1fr);
    padding: 10px 100px;
  }

  @media (min-width: 910px) and (max-width: 1400px) {
 
    width: 86%;
    margin: 0 7%;
    grid-template-columns: repeat(3, 1fr);
    padding: 10px 100px;
  }
  @media (min-width: 750px) and (max-width: 909px) {
    
    width: 95%;
    margin: 0 2.5%;
    grid-template-columns: repeat(2, 1fr);
    padding: 10px 100px;
  }
  width: 100%;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
  justify-items: center;
}
.explorer-img-ctn {
  width: 100%;
  // height: 311px;
  height: auto;
  overflow: hidden;
  position: relative;
  margin-top: -116px;
  padding-top: 116px;
  z-index: -1;

  // z-index: ;

  img {
    @media (min-width: 1400px) {
      width: 25%;
      margin: 20px 37.5% 20px 37.5%;
    }

    @media (min-width: 910px) and (max-width: 1400px) {
      width: 30%;
      margin: 20px 35% 20px 35%;
    }
    @media (min-width: 680px) and (max-width: 909px) {
      width: 40%;
      margin: 20px 30% 20px 30%;
    }
    width: 50%;
    margin: 20px 25% 0 25%;
    object-fit: cover;
    z-index: 100;

    // height: 100%;
    // filter: brightness(0) invert(1);
  }
}
