@import "./Variables.scss";

#intro-ctn {
  height: auto;
  background: url("../Assets/backgrounds/inicio-background.png");
  background-image: url("../Assets/backgrounds/inicio-background.png");
  background-repeat: no-repeat !important;
  background-size: cover !important;
  font-family: $font-regular;
  max-height: 2040px;
  @media (max-width: 550px) {
    max-height: 2600px;
    background: url("../Assets/backgrounds/inicio-background-mob.webp");
    background-image: url("../Assets/backgrounds/inicio-background-mob.webp");
  }

}
.first-section,
.Second-section {
  // height: min(100vh,680px);
  // height: 680px;
  max-height: 680px;
  border: 1px solid transparent;
}
.titleCtn {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  height: auto;
  display: block;
  @media (max-width: 844px) {
    width: 100%;
  }
}
.first-section > .titleCtn {
  @media (max-width: 844px) {
    width: 90%;
    margin: 5% 5%;
  }
  width: 50%;
  margin: 5% 25%;
}
.Second-section > .titleCtn {
  width: calc(70% - 3px);
  margin: 5% 15%;
}
.conjuntos {
  width: 50%;
  margin: 0 25%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  img {
    width: 100%;
    margin: 0 0 15px 0;
    @media (max-width: 550px) {
      width: auto;
      height: 110px;
      margin: 5px 0 15px 0;
      // border: 1px solid red;
    }
    @media (min-width: 550px) and (max-width: 844px) {
      width: 150%;
      margin: 5px 0 15px 0;
    }
  }
}
.title {
  color: $text-blue;
  font-family: $font-bold !important;
  font-size: min(46pt, 2.9rem);
  margin: 80px 0 0 0;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 30pt;
    margin: 30px 0 10px 0;
  }
  @media (min-width: 550px) and (max-width: 844px) {
    margin: 30px 0 15px 0;
  }
}
.title-others{
  font-size: min(25pt, 2.9rem);

}
.titleSp {
  display: flex;
    flex-flow: column wrap;
    align-items: center;
    padding-bottom: 25px;
}
.comparte {
  //  color: $text-blue;
  color: $text-blue;
  font-size: 19px;
  text-align: center;
  font-family: $font-regular;
  span:nth-child(1) {
    font-family: $font-bold;
  }
  span:nth-child(3) {
    display: none;
    @media (min-width: 550px) {
      display: inline;
    }
  }
  span:nth-child(4) {
    display: inline;
    @media (min-width: 550px) {
      display: none;
    }
  }
}

.input {
  // @media (max-width: 550px) {
  //   width: auto;
  //   height: 75px;
  //   margin: 5px 0 15px 0;
  // }
  box-shadow: 0px 4px 20px rgb(149 185 193);
  @media (max-width: 844px) {
    box-shadow: none;
    width: 90%;
    margin: 7% 5%;
    height: auto;
  }
  @media (min-width: 550px) {
    // box-shadow: none;
    background-color: $tarjetas-background;
    border: 2px solid $secondary-grey;
    height: 50px;
    max-height: 50px;
  }
  width: 50%;
  border-radius: 25px;
  padding: 0 3px;
  margin: 3% 25% 20px 25%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}
.input > div,
.input > div > input {
  font-size: 1.2rem;
}
.input > div {
  width: calc(70% - 3px);
  @media (max-width: 550px) {
    width: 100%;
  }
}
.input-mob {
  @media (max-width: 550px) {
    background-color: $tarjetas-background;
    border: 2px solid $secondary-grey;
    border-radius: 25px;
  }
  span {
    text-align: end;
  }
}

.input > div > input {
  @media (max-width: 844px) {
    width: 49%;
  }
  background-color: #f9f9f9;
  height: 40px;
  width: 69%;
  outline: none;
}
.input > div > span {
  width: 30%;
  margin-left: 1%;
  display: inline-block;
  font-family: $principal-font;
  font-weight: 600;
  @media (max-width: 550px) {
    width: auto;
    margin-right: 1%;
  }
}
.button {
  @media (max-width: 550px) {
    width: 100%;
    font-size: 0.8rem;
    margin: 7px 0 7px 0 !important;
  }
  @media (min-width: 550px) and (max-width: 844px) {
    width: 25%;
    font-size: 0.8rem;
  }
  border: 1px solid $principal-blue;
  background: $middle-blue;
  width: 30%;
  height: 40px;
  border-radius: 25px;
  cursor: pointer;
  font-family: $principal-font;
  color: $tarjetas-background;
  font-size: 1rem;
  font-weight: 600;
}
.down-input {
  @media (max-width: 550px) {
    margin: 0;
  }
  text-align: center;
  margin: 0 0 100px 0;
  font-weight: 100;
  font-size: 13pt;
  font-style: italic;
  // border:1px solid green;
  padding-top: 13px;
  height: 10vh;
  text-align: center;
  vertical-align: center;
}
.explicaciones {
  color: $text-blue;
  padding: min(37px, 8vh) 10vw;
  @media (max-width: 550px) {
    padding: 0;
    height: auto;
    padding: 0 0 50px 0;
  }
  @media (min-width: 550px) and (max-width: 844px) {
    padding: 0;
  }
  text-align: center;
  height: 680px;
  max-height: 115px;

  .cobramos-ctn {
    @media (max-width: 550px) {
      width: 100%;
      margin: 0;
      align-items: center;
      justify-items: center;
      grid-template-rows: 1fr 2fr;
      grid-template-columns: 1fr;
    }
    @media (min-width: 550px) and (max-width: 844px) {
      width: 100%;
      margin: 0;
    }
    display: grid;
    width: 60%;
    margin: 0 20%;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 10fr;
    // border: 1px solid red;
    .title {
      margin: 0;
    }

    img {
      height: 112px;
      @media (max-width: 550px) {
        height: 80px;
      }
    }
  }
  .grid-exp {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    .tarjetas-grid:nth-child(1) {
      justify-self: end;
      @media (max-width: 550px) {
        justify-self: center;
      }
    }
    .tarjetas-grid:nth-child(2) {
      justify-self: start;
      @media (max-width: 550px) {
        justify-self: center;
      }
    }
  }
}
.explicaciones,
.Second-section {
 

  h1 {
    width: 100%;

    text-align: center;
    display: inline-block;
  }

  .grid-exp {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    margin: min(5vh, 30.47px) 0 0 0;
    height: 75vh;
    max-height: 450px;
    align-items: start;
    @media (max-width: 550px) {
      height: 70%;
      max-height: none;
      grid-template-rows: repeat(2, 2fr);
      grid-template-columns: 2fr;
      max-height: 1600px;
      justify-items: center;
    }

    img {
      width: 70%;
      max-width: 392px;
      display: inline-block;
      margin-right: 10%;
      justify-self: right;
      align-self: start;
    }
    h2 {
      font-size: 1.5rem;
      padding: 0 0 8px 0;
      font-style: italic;
      align-self: start;
      font-family: $font-regular;
      //   grid-area: a;
    }
    .tarjetas-grid {
      background-color: $tarjetas-background;

      width: 349px;
      height: 248px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      border-radius: 25px;
      box-shadow: $shadow;
      align-self: center;
      justify-self: center;
      @media (max-width: 550px) {
      }
      @media (min-width: 550px) and (max-width: 844px) {
      }
      span {
        height: 70%;
        margin: 12.5% 10px;
        padding: 0;
      }
      h2 {
        font-size: 21px;
        font-style: normal;
        font-family: $font-bold;
        margin: 0 0 5% 0;
      }
      p {
        font-size: 16px;
        font-family: $font-regular;
      }
    }
  }
}

.Second-section {
  text-align: center;
  height: 650px;
  overflow: hidden;
  @media (max-width: 550px) {
    overflow: unset;
    height: 900px;
  }
  .wrapper {
    height: 680px;
    @media (max-width: 550px) {
      height: 900px;
    }
  }
  .title {
    // font-size: 46px;
    margin: 0 0 50px 0;
  }
  // background-color: red;
  .grid-exp {
    margin: 0 10%;
    grid-template-rows: repeat(1, 1fr);
    max-height: 309px;
    @media (max-width: 550px) {
      max-height: 600px;
      grid-template-rows: 40% 60%;
    }
  }
  #pasos-list {
    width: 80%;
    height: 95%;
    margin: 2.5% 0;
    text-align: start;
    justify-content: space-around;
    display: flex;
    flex-flow: column wrap;
    @media (max-width: 550px) {
      height: 100%;
    }
    li {
      list-style: none;
    }

    .pasos {
      color: $text-blue;
      font-family: $font-bold;
      font-size: 12px;
    }
    .pasos-exp {
      color: $tarjetas-background;
      font-family: $font-regular;
      font-size: 15px;
    }
    // padding: 5% 0 5% 16% !important;
    // justify-content: start !important;
  }

  img {
    grid-column-start: 1;
    grid-column-end: 2;
    width: 70%;
    max-width: 392px;
    display: inline-block;
    margin-right: 10%;
    justify-self: right;
  }
}

footer {
  padding: 2%;
  background-color: $terciary-grey;
  width: 100%;
  height: 30vh;
  overflow: hidden;
  .grid-footer {
    display: grid;
    padding: 0 5%;
    grid-template-columns: repeat(3, 1fr);
    color: $tarjetas-background;
    align-items: center;
    height: 100%;
    @media (max-width: 550px) {
      grid-template-columns: 1fr 0 1fr;
    }

    span {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      height: 100%;
      #avisos-preguntas {
        margin: 20px 0;
        display: flex;
        flex-flow: column;
        a,
        p {
          font-family: $font-thin;
          min-height: 49px;
        }
        p {
          padding-top: 10px;
          div {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            svg {
              margin: 0 5px 0 0;
            }
          }
          font-size: 12px;
        }
      }
    }

    #redes-ctn {
      height: 100%;
      align-items: flex-end;

      #redes {
        margin: 20px 0;
        display: flex;
        font-size: 25px;
        width: 50%;
        justify-content: space-between;
        @media (max-width: 550px) {
          width: 60%;
        }
        a {
          margin: 0 10px 0 0;
        }
      }
    }
  }
}
