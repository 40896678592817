@import "./styles/Variables.scss";

body {
  margin: 0;
  padding-right: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .first-section, #explorer-comp{
    padding-top: 56px;
  }
  a {
    text-decoration: none !important ;
    color: $primary-grey;
    z-index: 8997;
  }
  .wrapper {
    width: 100%;
    max-width: 1280px;
    max-height: 680px;
    @media (max-width: 550px) {
      max-height: none !important;
    }
  }
  .wrapper-ctn {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .wrapper-ctn-checa {
    height: 100%;
    min-height: 100vh;
    background: rgb(249, 249, 249);
    background: linear-gradient(
      140deg,
      rgba(249, 249, 249, 1) 0%,
      rgba(232, 238, 249, 1) 40%,
      rgba(183, 232, 247, 1) 53%,
      rgba(134, 226, 245, 1) 73%,
      rgba(0, 209, 238, 1) 88%,
      rgba(0, 180, 242, 1) 94%
    );
    .title-ctn {
      width: 50%;
      margin: 50px 25%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 550px) {
        width: 70%;
      margin: 50px 15%;
      }
    }
    img{
      width: 60%;
      @media (max-width: 550px) {
        width: 100%;
      }
    }
  }
  .wrapper-class {
    max-height: 680px;
    @media (min-width: 550px) and (max-width: 844px) {
      width: 90%;
      margin: 0 5% 0 5%;
    }
    @media (max-width: 550px) {
      max-height: none;
      // background-color: red;
      margin: 0;
      //  border: 1px solid green;
    }
  }
}
.wrapper-class-c-perfil {
  @media (max-width: 550px) {
    margin: 0 !important;
  }
}

.logo,
.logoWhite {
  @media (max-width: 844px) {
    font-size: 22px;
  }
  font-size: 40px;
  font-family: "Barlow", sans-serif !important;
   z-index: 0;
}
.logo {
  color: $principal-blue !important;
}
.logoWhite {
  color: $primary-grey !important;
}

body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
  font-family: $principal-font;
  color: $text-grey;
}
#root {
  width: 100%;
  height: 100%;
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

*,
*:before,
*:after {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

code {
  font-family: $principal-font;
}

#mob-navbar {
  background-color: transparent;
  box-shadow: none;
  .logoWhite {
    overflow: hidden;
    font-size: 18px;
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    max-height: 60px;
    img {
      width: 55px;
      height: auto;
      margin-top: 5px;
    }
  }
}
