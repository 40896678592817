@import "./Variables.scss";
#perfil-exp {
  height: auto;
  background: url("../Assets/backgrounds/perfilExplorador.png");
  background-image: url("../Assets/backgrounds/perfilExplorador.png");
  background-repeat: no-repeat;
  font-family: $font-regular;
  background-size: cover;
  padding-bottom: 100px;

  #perfil-exp-nav {
    width: 100%;
    height: 100px;
    padding: 0 20px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    .pill {
      font-family: $font-regular;
      color: $text-grey;
      font-size: 16px;
      background: $tarjetas-background;
      border: 1px solid $terciary-grey;
      border-radius: 25px;
      margin: 0px 5px;
      transition: all 0.3s ease;
    }
    .pill-first {
      padding: 5px 15px;
      display: none;
      &:hover::after {
        content: "Función no disponible aun.";
        position: absolute;
        top: 12%;
        left: 88%;
        transform: translateX(-50%);
        padding: 10px;
        background-color: #000;
        color: #fff;
        white-space: nowrap;
        font-size: 14px;
        border-radius: 5px;
        opacity: 0.7;
        z-index: 1000;
      }
      &:disabled {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #999999;
        cursor: not-allowed;
      }
    }
    .pill-second {
      padding: 5px 8px;
      text-align: center;
      text-justify: center;
      color: $terciary-grey;
      &:hover {
        border: 1px solid $darker-blue;
        background-color: $banner-background;
        color: $darker-blue;
        cursor: pointer;
      }
    }
  }
  #perfil-exp-grid {
    // background-color: red;
    @media (min-width: 950px) {
      width: 70%;
      margin: 30px 15%;
    }
    width: 85%;
    margin: 30px 7.5%;
    column-gap: 8%;
    row-gap: 3%;
    @media (min-width: 950px) {
      height: 650px;
      // background-color: red;
    }
    height: 1350px;
    // height: auto;
    display: grid;
    @media (min-width: 950px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 4fr 6fr;
      grid-area: "rec sup" "his sup" "his sup" "his sup" "con sup" "con sup"
        "con sup" "con sup";
    }

    // @media (min-width: 910px) and (max-width: 1400px) {

    // }
    // @media (min-width: 750px) and (max-width: 909px) {

    // }
    grid-template-columns: 1fr; //repeat(2, 1fr);
    grid-template-rows: 0.3fr 1fr 3.5fr 2fr;
  }
  .tarjetas {
    background-color: $tarjetas-background;
    border-radius: 25px;
    box-shadow: $shadow;
    overflow: hidden;
    padding: 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    h5 {
      width: 95%;
      margin: 0 0 0 5%;
      text-align: initial;
      font-size: 13pt;
      color: $text-grey;
      // background-color: red;
      font-family: $font-bold;
    }
    p {
      width: 90%;
      // margin-top: 1%;
      height: 90%;
      margin: 5%;
      // margin: 0 30% 0 0;
      // background-color: red;
    }
    table {
      width: 100%;
      height: 100%;
      thead {
        height: 20%;
      }
      tbody {
        height: 80%;
      }
      tr {
        display: flex;
        flex-flow: row wrap;

        align-items: center;
        height: 20%;
        font-family: $font-medium;
        border-bottom: 1.5px solid $secondary-grey;
        th {
          justify-self: flex-start;
          text-align: initial;
          width: 100%;
          margin: 0;
          // width: 30%;
          // margin: 0 70% 0 0;
          // background-color: red;
          font-family: $font-bold;
        }
        td {
          // color: red;

          padding: 0 0 0 20px;
        }
      }
      tr:last-child {
        border-bottom: none;
      }
    }
  }
  .tarj-table {
    padding: 0 !important;
    table {
      width: 100%;
    }
    thead {
      tr {
        height: 100%;
      }
    }
    td {
      display: grid;
      width: calc(100% - 40px);
      @media (min-width: 950px) {
        // background-color: red;
        grid-template-columns: 11.9% 49.7% 38.4%;
      }
      grid-template-columns: 45px 100px 145px;
      align-items: center;
    }
    .perfil-tabla {
      width: 35px;
      height: 35px;
      // background-color: $text-blue;
      border-radius: 50%;
      margin: 0 15px 0 0;
      color: white;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      background: rgb(249, 249, 249);
      background: linear-gradient(
        141deg,
        rgb(232, 238, 249),
        rgb(183, 232, 247),
        rgb(134, 226, 245),
        rgb(0, 209, 238),
        rgb(0, 180, 242),
        rgb(119, 0, 179)
      );
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.12);
      // background: linear-gradient(140deg, rgba(249,249,249,1) 0%, rgba(232,238,249,1) 40%, rgba(183,232,247,1) 53%, rgba(134,226,245,1) 73%, rgba(0,209,238,1) 88%, rgba(0,180,242,1) 94%)
      // display: inline-block;
    }
    .name-perf-tabla,
    .recib-perf-tabla {
      font-size: 11pt;
      color: $text-grey;
    }
    .name-perf-tabla {
      font-family: $font-bold;
      padding-left: 5px;
    }
    .recib-perfil-tabla {
      text-align: end;

      font-family: $font-regular;
      margin: 0 0 0 10px;
    }
  }

  #perfil-exp-recibido {
    border: 1.5px solid $principal-blue;
    color: $principal-blue;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    font-family: $font-medium;
    img {
      position: relative;
      @media (min-width: 950px) {
        height: 90%;
      }
      height: 50%;
      padding: 0 10px;
    }
    @media (max-width: 950px) {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }
  }
  #perfil-exp-apoyo {
    position: relative;
    @media (min-width: 950px) {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 4;
    }
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  
.user-inactive::before {
  content: "¡Vaya! Este usuario aún no ha conectado un medio de pago.";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15%;
  background-color: rgba(33, 33, 33, 0.55);
  color: $primary-grey;
  font-size: 13pt;
  font-family: "Montserrat-Bold.ttf", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; 
}

  .user-banner {
    width: 100%;
    overflow: hidden;
    height: 35vh;

    div {
      width: 200%;
      height: 217%;
      display: flex;
      display: flex;
      flex-flow: row wrap;
      background-color: $banner-background;
      // background-color: red;
      justify-content: center;
      //   background-image: url("../Assets/saltarin-blanco.png");
      background-repeat: round;
      // transform: rotate(-10deg);
      background-size: 60px;
      position: relative;
      top: -195px;
      left: -290px;
    }
    // img{width: 17%;}
  }
  .user-profile-pic {
    width: 100%;
    height: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    img {
      background-color: $gradient-principal-blue;
      position: relative;
      top: -90px;
      //   left: 50px;
      border-radius: 50%;
      border: 4px solid #f6f6f6;
      width: 140px;
      height: 140px;
      text-align: center;
      vertical-align: center;
      object-fit: cover;
    }
  }

  .section {
    margin: 0 50px;
    background-color: $primary-grey;
    // border: 1px solid red;
  }
  .name-ctn {
    // height: 100vh;
    padding: 60px 0 0 0;

    text-align: center;
    h1 {
      font-size: 2rem;
      font-weight: 600;
      color: $darker-blue;
    }
  }
  .stack-alert {
    left: 20px;
    width: 30%;
    position: absolute;
    top: 87px;
  }
  .alert {
    border: 1.5px solid rgb(22, 117, 27);
    border-radius: 25px;
  }
}
