$principal-blue: #0057ff;
$banner-background: #eef1f8;
$middle-blue: #004CBC;
$darker-blue: #002B5A;
$gradient-principal-blue: #0080DA;
$gradient-secondary-color: #00D1EE;
$gradient-third-color: #00F7FC;
$primary-grey: #f6f6f6;
$secondary-grey: #d0d4dd;
$terciary-grey: #434b57;

$text-grey: #5e5e5e; 
$text-blue: #002b5a; 

$tarjetas-background:#f9f9f9;

$principal-font: "Montserrat-Regular", sans-serif;
$font-thin: "Montserrat-Thin", sans-serif;
$font-regular: "Montserrat-Regular.ttf", sans-serif;
$font-bold: "Montserrat-Bold.ttf", sans-serif;
$font-medium: "Montserrat-Medium.ttf", sans-serif;
$font-extra-bold: "Montserrat-ExtraBold.ttf", sans-serif;
$shadow:1px 5px 10px 2px rgba(0,0,0,0.3);

$font-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$font-size-default: 14px;
$font-weight-medium: 500;

$button-padding: 12px 16px 12px 42px;
$transition-timing: 0.3s;
$medium-device: 844px;




@font-face {
  font-family: "Jack Armstrong";
  src: url("../Assets/JackArmstrong.ttf") format("truetype");
}
@font-face {
  font-family: "Barlow";
  src: url("../Assets/Barlow-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Thin";
  src: url("../Assets/Montserrat/Montserrat-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular.ttf";
  src: url("../Assets/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium.ttf";
  src: url("../Assets/Montserrat/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold.ttf";
  src: url("../Assets/Montserrat/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-ExtraBold.ttf";
  src: url("../Assets/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
}