@import "./Variables.scss";

.css-5xe99f-MuiLinearProgress-bar1{
  background-color: $principal-blue !important;
}
.css-5xe99f-MuiLinearProgress-bar1,
.css-eglki6-MuiLinearProgress-root {
  border-radius: 25px;

}
#supporter-grid {
  color: $text-grey;
  display: grid;
  width: 100%;
  grid-template-rows: 0.4fr 0.7fr 2fr 0.5fr 1.6fr;
  grid-template-columns: 1fr;

  // background-color: red;
  #first-row {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    #img-button-ctn {
      width: 100%;
      
      display: grid;
      grid-template-rows: 1fr;
      // grid-template-columns: 3fr 1fr;
      grid-template-columns: 1fr 8fr 4fr;
      margin: 10px 10px 0 10px;
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    .mas-menos-btn-ctn {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 0 0 12px 0;
      // justify-content: center;

      .mas-menos-btn {
        border: 1.5px solid $principal-blue;
        display: grid;
        width: 50%;
        grid-template-rows: 1fr;
        grid-template-columns: 30% 40% 30%;
        justify-content: center;
        //   height: 20px;
        padding: 0;
        button {
          background-color: $principal-blue;
          color: $tarjetas-background;
          margin: 0;
          padding: 0;
          border: 1px solid $principal-blue;
          cursor: pointer;
        }
        input {
          outline: none;
          text-align: center;
          color: $text-grey;
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
    img {
      //    height: 100%;
      width: 100%;
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
}
#saltarin-count {
  // background-color: red;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  width: 100%;
  span {
    margin: 0 0 0 45%;
    @media (max-width: 550px) {
      margin: 0 0 0 40%;
    }
  }
  ul {
    display: flex;
    flex-flow: row wrap;
    width: 96%;
    margin: 10px 2% 0 2%;
    justify-content: space-around;
    @media (max-width: 550px) {
      width: 100%;
      margin: 10px 0 0 0;
      li:nth-child(10) {
        display: none;
      }
    }

    li {
      list-style: none;
    }
    .saltarin,
    .saltarin-disabled {
      height: 24px;
      // width: 32px;
      cursor: pointer;
    }
    .saltarin-disabled {
      filter: grayscale(1);
      opacity: 0.5;
    }
  }
}
#saltarin-count > span,
#supp-final-ctn > div > span {
  font-family: $font-regular;
  font-size: 13px;
  color: $text-blue;
  align-self: flex-end;
}
#inputs-ctn {
  width: 90%;
  margin: 0 5%;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;

  .inputs-supporter {
    width: 100%;
    margin: 5px 0;
    input,
    textarea {
      color: $text-grey;
    }
  }

  input {
    width: 100%;
  }
  #circular-progress {
    // background-color: red;
    height: 100%;
    padding: 20px 0;
    display: grid;
    text-align: center;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    h4 {
      animation: late 2s ease-in infinite;
    }
  }
}
#btn-supp-ctn {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 60%;
  margin: 0 20%;
  grid-column-start: 1;
  grid-column-end: 3;
  .btn-supp {
    height: 40px;
    margin: 10px 0 0 0;
    padding: 10px 80px;
    border-radius: 10px;
    // background-color: red;
    font-size: 13px;
    background-color: $principal-blue;
    color: $tarjetas-background;
    font-family: $font-regular;
    cursor: pointer;
  }
  .btn-supp-mp {
    padding: 10px 20px !important;
  }
  .btn-supp-load {
    p {
      animation: late 2s ease-in infinite;
    }
  }
}
#supp-final-ctn {
  // background-color: red;
  grid-column-start: 1;
  grid-column-end: 3;

  width: 90%;
  margin: 5% 5% 0 5%;
  table {
    height: 80% !important;
   
  }
  tr {
    border-bottom: none !important;
    justify-content: center;
    td {
      width: 80%;
      font-size: 13px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      @media (max-width: 550px) {
        width: 100%;
      }
    }
  }
  tr:last-child {
    border-top: 1.5px solid $secondary-grey;
    font-family: $font-bold !important;
    font-size: 15px !important;
  }
  p,
  a {
    font-family: $font-medium;
    font-size: 9px;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    height: auto !important;
    text-align: justify;
    color: $text-grey;
  }
}

@keyframes late {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
