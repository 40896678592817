@import "./Variables.scss";

.completarComp {
 
  .box {
    width: 40%;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
}
.perfilComp {
  @media (max-width: 600px) {
    padding-top: 56px;
  }
  .box {
    width: 70%;
    @media (max-width: 550px) {
      width: 100%;
    }
  }
  .button {
    width: 55%;
    height: 50px;
  }
}
.profile-section {
  width: 100%;
  max-height: none !important;
  height: auto;

  background: rgb(249, 249, 249);
  background: linear-gradient(
    140deg,
    rgba(249, 249, 249, 1) 0%,
    rgba(232, 238, 249, 1) 40%,
    rgba(183, 232, 247, 1) 53%,
    rgba(134, 226, 245, 1) 88%,
    rgba(0, 209, 238, 1) 95%
  );
  background-repeat: no-repeat;
  font-family: $font-regular;
  background-size: cover;
  padding-bottom: 100px;
  overflow: visible !important;
  div {
    max-height: none !important;
  }
  .button {
    margin-top: 15px;
  }
  .css-1gzyby-MuiInputBase-root-MuiFilledInput-root,
    .css-1xab92c-MuiInputBase-root-MuiFilledInput-root
    // .css-1vbc0rj-MuiInputBase-input-MuiFilledInput-input
  {
    background-color: $tarjetas-background !important;
    box-shadow: $shadow;
    color: $text-blue;
    font-family: $font-regular;
  }
  label.Mui-focused {
    font-family: $font-bold;
    color: $text-grey !important;
  }
  .css-1gzyby-MuiInputBase-root-MuiFilledInput-root:after,
  .css-1xab92c-MuiInputBase-root-MuiFilledInput-root:after {
    border-bottom: 2px solid $principal-blue !important;
  }
  .css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: #d32f2f !important;
  }
}

#agrega-foto {
  text-align: center;
  margin: 10px 0 20px 0;
  color: #717171;
}
.perfil-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  h1 {
    margin: 15px 40px 40px 40px;
    color: $darker-blue;
  }

  .box {
    .inputs-profile {
      width: 80%;
      // margin: 10% 0;
    }
  }
  .inputs-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .circle-photo {
    overflow: hidden;
    border: 3px dashed #717171;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    input {
      opacity: 0 !important;
      height: 100%;
      width: 100%;
      cursor: pointer;

      z-index: 1000;
    }
    .input-imagen {
      position: relative;
      top: -120px;
    }
    svg {
      position: absolute;
      color: #717171;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }
  }
  .logo {
    font-size: 15px;
  }
  #aviso-perfil {
    max-width: 390px;
    margin: 25px 0 0 0;
    font-size: 15px;
    color: $text-grey;
    text-align: center;
  }
  #conf {
    overflow: visible;
  }
}

.button:disabled {
  background-color: #c9c8c8;
  border: none;
  cursor: not-allowed;
}
