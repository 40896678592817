@import "./Variables.scss";

a {
  text-decoration: none;
}

$default-font: "montserrat", sans-serif;
$default-font-size: 18px;
$default-font-weight: bold;
$default-z-index: 1000;
$btn-padding: 15px 20px;
$btn-font-size: 15px;
$btn-border-radius: 25px;
$btn-transition: 0.5s;
$btn-background-size: 200% auto;
$btn-hover-color: #fff;

@mixin button-styles {
  z-index: $default-z-index;
  padding: $btn-padding;
  text-align: center;
  font-size: $btn-font-size;
  transition: $btn-transition;
  background-size: $btn-background-size;
  color: white;
  border-radius: $btn-border-radius;
  display: block;

  &:hover {
    background-position: bottom right;
    color: $btn-hover-color;
    text-decoration: none;
  }

  a,
  .bienvenido {
    color: white;
    justify-content: center;
    display: flex;
    flex-flow: row;
  }

  svg {
    font-size: 20px;
    margin-right: 5px;
  }
}

nav {
  width: 90%;
  max-height: 70px;
  margin: 25px 5%;
  padding-bottom: 14px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid $middle-blue;

  ul {
    width: 45%;
    max-width: 650px;
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    list-style: none;

    li {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
    }

    a,
    .bienvenido {
      color: $principal-blue;
      font-family: $default-font;
      font-weight: $default-font-weight;
      font-size: $default-font-size;
      z-index: $default-z-index;
    }
    a:hover {
      color: $middle-blue;
    }
    .bienvenido {
      cursor: default;
    }
    .ul-CreaWhite a,
    .ul-CreaWhite .bienvenido {
      color: $primary-grey;
    }

    .btn-grad {
      @include button-styles;
      cursor: pointer;
      background-image: linear-gradient(
        to right,
        $gradient-principal-blue 0%,
        $gradient-secondary-color 51%,
        $gradient-principal-blue 100%
      );
      box-shadow: 0 0 20px #eee;
    }

    .btn-gradWhite {
      @include button-styles;
      cursor: pointer;
      background-image: linear-gradient(
        to right,
        $principal-blue 3%,
        $middle-blue 31%,
        $darker-blue 100%
      );
    }
  }
}
