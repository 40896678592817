@import "./Variables.scss";

#alert-dialog-slide-description {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

#\:r0\:,#url-input-ctn >input {
    text-align: center; 
  font-family: $font-regular;
}
#generated-img{
  // width: 20vw;
  width: 85vw;
  @media screen and (min-width: 571px) and (max-width: 900px) {
    width: 55vw;  // Ajusta este valor según tu necesidad
  }

  @media screen and (min-width: 900px) and (max-width: 1200px) {
    width: 40vw;  // Ajusta este valor según tu necesidad
  }

  @media screen and (min-width: 1200px) and (max-width: 1620px) {
    width: 30vw;  // Ajusta este valor según tu necesidad
  }

  @media screen and (min-width: 1620px) and (max-width: 1899px) {
    width: 25vw;  // Ajusta este valor según tu necesidad
  }

  @media screen and (min-width: 1900px) {
    width: 20vw;
  }
}

.svg-share{
  width: 14% !important;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  padding: 5px 6px;
  &:hover{
    background-color: rgba($secondary-grey,0.4) !important;
    padding: 5px;
    cursor: pointer;
   }
  svg{
    font-size: 1.2rem;
    color: $text-grey;
  }
}
#url-input-ctn{
    width: 90%;
    margin: 10px 5%;
    padding: 10px 2.5%;    
    border: 1px solid $terciary-grey;
    border-radius: 25px;    
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    input{
        width: 90%;
        text-align: start;
        outline: none;
    }
    span{
        padding: 5px;
        border-radius: 25px;
        transition: background-color 0.3s ease;
       &:hover{
        background-color: rgba($secondary-grey,0.4);
       }
       display: flex;
       flex-flow: row wrap;
       justify-content: center;
       align-items: center;
       svg{
        width: 80%;
       }
    }
}
.MuiDialog-root{
  z-index: 9999;
}
body > div.MuiDialog-root.MuiModal-root.css-210kcl-MuiModal-root-MuiDialog-root > div.MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container > div > div > div > div > div{
    justify-content: center;
}
#alert-dialog-slide-description button {
//   background-color: #4caf50; /* Color de fondo */
  background: rgb(249,249,249);
  background: linear-gradient(160deg,$gradient-third-color 0%, $gradient-secondary-color 25%, $gradient-principal-blue 94%);
  border: none; /* Remueve los bordes */
  color: $primary-grey; /* Color del texto */
  padding: 10px 32px; /* Espaciado alrededor del texto */
  text-align: center; /* Alinea el texto al centro */
  text-decoration: none; /* Remueve la decoración del texto (subrayado) */
  display: inline-block;
  font-size: 14px; /* Tamaño del texto */
  margin: 4px 2px;
  cursor: pointer; /* Cambia el cursor a un puntero al pasar el mouse encima */
  border-radius: 25px; /* Suaviza los bordes del botón */
  transition: background-color 0.3s ease; /* Agrega una transición al cambiar el color de fondo */
}

#alert-dialog-slide-description button:hover {
    background: linear-gradient(140deg,$gradient-third-color 0%, $gradient-secondary-color 45%, $gradient-principal-blue 94%);
}
