@import "Variables";

.card {
  cursor: pointer;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;

  a {
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    display: grid;
    height: 100%;
    width: 100%;
    // border: 1px solid red
    background-color: $tarjetas-background;
    grid-template-columns: 100%;
    grid-template-rows: 2fr 2fr 1fr;
    grid-template-areas: "img" "text" "pill";
  }
  //   border: 1px solid blue;
  max-width: 300px;
  max-height: 200px;
  height: 200px;
  margin: 20px;
  div {
    width: 100%;
  }
  .img-ctn {
    max-height: 90px;
    overflow: hidden;
    background-color: $gradient-principal-blue;
    border-radius: 25px 25px 0 0;
    -webkit-border-radius: 25px 25px 0 0;
    -moz-border-radius: 25px 25px 0 0;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
  .text-ctn {
    // height: 60%;
    height: 100%;
    padding: 5px 8px;
    color: $terciary-grey; // padding: 5px 8px ;
    // display: flex;
    // flex-flow: row wrap;
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: 1fr 2fr;
    grid-template-areas: "h2" "p";
    h2 {
      width: 100%;
      font-size: 16px;
    }
    p {
      font-size: 13px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      //   height: ;
    }
    h2 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .grey-pill {
    display: flex;
    flex-flow: row wrap;
    // border: 2px solid blueviolet;
    justify-content: flex-end;
    align-content: center;
    padding: 0 15px;
    // background-color: red;
    label {
      // border: 1px solid red;
      padding: 5px 8px;
      border-radius: 25px;
      background-color: #dedede;
      color: $terciary-grey;
      font-size: 11px;
      position: relative;
      align-self: end;
      // align-self: flex-end;
      // margin: 0 0 3px 75%;
      //   bottom: 15px;
      //   right: -1px;
    }
  }
}
