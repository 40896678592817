@import "./Variables.scss";

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin section-sizes($width, $height) {
  @media (max-width: 844px) {
    height: 50%;
    width: 100%;
  }
  height: $height;
  width: $width; 
}

$base-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

.login-comp {
  width: 100%;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  background: rgb(0, 215, 244);
  background: linear-gradient(
    28deg,
    rgba(0, 215, 244, 1) 0%,
    rgba(0, 209, 241, 1) 24%,
    rgba(0, 202, 238, 1) 50%,
    rgba(0, 153, 227, 1) 70%,
    rgba(0, 150, 226, 1) 100%
  );

  @include flex-center;
  flex-flow: row wrap;

  .login-modal {
    @include flex-center;
    @media (max-width: 844px) {
        margin: 1vh 1vw;
      }
    flex-flow: row wrap;

    width: 86vw;
    max-width: 1375px;
    height: 86vh;
    max-height: 655px;
    margin: 7vh 7vw;
    border-radius: 25px;
    overflow: hidden;



    .blue-section {
      @include section-sizes(60%, 100%);
      @include flex-center;
      flex-flow: row wrap;
      justify-content: space-around;
      padding: 10px 90px 90px 90px;
      background-color: $principal-blue;
      color: $primary-grey;

      a {
        text-align: center;
      }
      img {
        width: 67%;
        height: auto;
        object-fit: cover;
      }
      div {
        text-align: center;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;

        h1 {
          margin-bottom: 10px;
          font-size: 23px;
          font-family: "Jack Armstrong", sans-serif;
        }
        p {
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          font-family: "Montserrat", sans-serif;
          font-size: 20px;
          line-height: 25px;
          span {
            width: 100%;
          }
        }
      }
    }
    .grey-section {
      @include section-sizes(40%, 100%);
      @include flex-center;
      flex-flow: row wrap;
      background-color: $primary-grey;
      height: 100%;
      width: 40%;

      div {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        height: 49.75%;
      }
      .login-div {
        height: 59.5%;
      }

      h1 {
        @media (max-width: 844px) {
            margin: 5% 0 0 10%;
          }
        color: $principal-blue;
        width: 100%;
        text-align: start;
        margin: 20% 0 0 10%;
      }
      .login-label {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        text-align: center;
        justify-content: space-between;
        margin: 0 30% 0 10%;
        p {
          font-size: 14px;
          display: inline-block;
        }
        :nth-child(2) {
          color: $principal-blue;
          font-weight: bold;
        }
      }
      input {
        outline: none;
        border-bottom: 1px solid $secondary-grey;
        background-color: transparent;
        width: 80%;
        height: 35px;
        margin: 0 20% 0 10%;
        font-size: 1rem;
      }

      .separation-line {
        height: 0.5%;
        background-color: $secondary-grey;
        margin: 0 15%;
        width: 100%;
        border-radius: 25px;
      }
      @media (max-width: 844px) {
        height: 50%;
        width: 100%;  
        .buttons-div{
          padding: 0px 15px 30px 15px;
          height: auto;
        }
      }
      .buttons-div {
        padding: 30px;
        align-items: flex-start;
        height: 40%;
      }
 
      .magic-link-login {
        background-color: $principal-blue;
        color: $primary-grey;
        border: none;
        border-radius: 25px;
        padding: 9px 40px;
        margin: 5px 0 10px 0;
        font-size: 15px;
        font-weight: bold;
        cursor: pointer;
        &:disabled {
          background-color: #c9c8c8;
          cursor: not-allowed;
        }

        &:nth-child(1) {
          background-color: $principal-blue;
          color: $primary-grey;
        }

        &:nth-child(2) {
          background-color: $primary-grey;
          border: 1px solid $principal-blue;
          color: $principal-blue;
        }
      }
      .linear-ctn{
        width: 100% !important;
        margin: 0 15% !important;
        height: 2% !important;
       
      }
      .linear-ctn > span{
        width: 100% !important;
        border-radius: 25px;
      }
      button.login-with-google-btn {
        transition: background-color 0.3s, box-shadow 0.3s;
        padding: 12px 16px 12px 42px;
        border: none;
        border-radius: 3px;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
        color: #757575;
        font-size: 14px;
        font-weight: 500;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
          sans-serif;

        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
        background-color: white;
        background-repeat: no-repeat;
        background-position: 12px 11px;

        &:hover {
          box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04),
            0 2px 4px rgba(0, 0, 0, 0.25);
          cursor: pointer;
        }

        &:active {
          background-color: #eeeeee;
        }

        &:focus {
          outline: none;
          box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04),
            0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc;
        }

        &:disabled {
          filter: grayscale(100%);
          background-color: #ebebeb;
          box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04),
            0 1px 1px rgba(0, 0, 0, 0.25);
          cursor: not-allowed;
        }
      }
    }
  }
  .error {
    width: 150px !important;
    height: 50px !important;
    border-radius: 10px;
    right: 105px;
    position: absolute;
    top: 65px;
    background: #ecc0c8;
    color: #b00020;
    font-size: 20px;
    // animation: name duration timing-function delay iteration-count direction fill-mode;
  }
}
