@import "./Variables.scss";


.userInicio-ctn{
  height: 90vh;
  background: rgb(249,249,249);
  background: linear-gradient(140deg, rgba(249,249,249,1) 0%, rgba(232,238,249,1) 40%, rgba(183,232,247,1) 53%, rgba(134,226,245,1) 73%, rgba(0,209,238,1) 88%, rgba(0,180,242,1) 94%);
  display: grid !important;
  grid-template-rows: auto 1fr;
  @media (max-width: 600px) {
    margin: 0 !important;
    padding: 56px 4% 0 4%;
    .lineStepper{
      // max-width: 400;
      width: 75%;
      margin:0 15%;
      align-self: start;
      padding-top: 10%;
    }
  }
}
.title-ctn{
  padding: 0 0 4% 0;
}
.vertical-ul {
    width: 75%;
    max-height: 100px;
    height: 10vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: $text-grey;
    color: $banner-background;
    position: fixed;
    bottom: 0;
    @media (max-width: 600px) {
      width: 100%;
      margin: 0;
      right: 0;

    }
    li {
      list-style: none;
      flex: 1; // Esto hará que cada li tenga el mismo ancho
      text-align: center; // Esto centrará el texto en cada li
      position: relative; // Esto es necesario para posicionar el after absolutamente
      font-family: $font-medium;
      &:nth-child(2):after,
      &:nth-child(3):after {
        @media (min-width: 600px) {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 20px;
        background: $banner-background;}
      }
      &:nth-child(1){
        font-family: $font-thin;
        @media (max-width: 600px) {
               flex: 0 0 30%;
               text-align: right;
        }
      }
      &:nth-child(1):after{
        @media (max-width: 600px) {
        content: "";
        position: absolute;
        right: -25%;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 20px;
        background: $banner-background;
      }
    }
      
    }
  
    @media (max-width: 600px) {
      flex-direction: row;
  
      li:nth-child(2):after,
      li:nth-child(3):after {
        width: 20px;
        height: 2px;
        right: auto;
        bottom: 0;
        top: auto;
        transform: translateX(-50%);
      }
    }
  }
  